<template>
  <el-row style="width: 100%;">
    <el-col :span="8">
      <el-select
        :model-value="props.dl"
        filterable
        placeholder="请选择大类"
        size="large"
        style="width: 95%;"
        @change="dlChange"
      >
        <el-option
          v-for="item in dlOptions"
          :key="item.id"
          :label="item.classname"
          :value="item.id"
        />
      </el-select>
    </el-col>
    <el-col :span="8">
      <el-select
        :model-value="props.zl"
        filterable
        placeholder="请选择中类"
        size="large"
        style="width: 95%;"
        @change="zlChange"
      >
        <el-option
          v-for="item in zlOptions"
          :key="item.id"
          :label="item.classname"
          :value="item.id"
        />
      </el-select>
    </el-col>
    <el-col :span="8">
      <el-select
        :model-value="props.xl"
        filterable
        placeholder="请选择小类"
        size="large"
        style="width: 95%;"
        @change="xlChange"
      >
        <el-option
          v-for="item in xlOptions"
          :key="item.id"
          :label="item.classname"
          :value="item.id"
        />
      </el-select>
    </el-col>
  </el-row>
</template>

<script setup>
import { ref, reactive, onMounted, defineEmits, defineProps } from 'vue'
import * as GoodsClassApi from '@/api/goods-class.js'

const props = defineProps({
  dl: {
    type: [String, Number],
    require: true
  },
  zl: {
    type: [String, Number],
    require: true
  },
  xl: {
    type: [String, Number],
    require: true
  }
})

const emits = defineEmits(['update:dl', 'update:zl', 'update:xl'])

const dlOptions = ref([])
const zlOptions = ref([])
const xlOptions = ref([])

onMounted(() => {
  getClass(0)
})

/**
 * 获取微信商品分类
 * @param {Number} level 层级(0,1,2)
 * @param {String} pid 上级分类id
 */
const getClass = async (level = 0, pid = '') => {
  const param = {
    level: level,
    pid: pid
  }
  try {
    const res = await GoodsClassApi.list(param)
    if (res.length) {
      switch (level) {
        case 0:
          dlOptions.value = res
          break

        case 1:
          zlOptions.value = res
          break

        case 2:
          xlOptions.value = res
          break

        default:
          break
      }
    }
  } catch (error) {}
}

const dlChange = e => {
  zlOptions.value = []
  xlOptions.value = []
  emits('update:dl', e)
  emits('update:zl', '')
  emits('update:xl', '')
  getClass(1, e)
}

const zlChange = e => {
  xlOptions.value = []
  emits('update:zl', e)
  emits('update:xl', '')
  getClass(2, e)
}

const xlChange = e => {
  emits('update:xl', e)
}
</script>

<style lang="scss" scoped></style>
