<template>
  <div class="user-manage-container">
    <el-card class="header">
      <search @search="searchClick" @clear="clearClick"></search>
    </el-card>
    <el-card>
      <div class="table-head-box">
        <el-button
          style="float: right"
          :icon="Refresh"
          size="small"
          round
          @click="getListData"
        ></el-button>
        <el-button
          v-permission="['addGoods']"
          class="button"
          type="primary"
          size="small"
          @click="handleCreate"
        >
          新增
        </el-button>
        <!-- <el-button
          v-permission="['deleteGoods']"
          class="button"
          type="danger"
          size="small"
          :disabled="!selectedIndexs.length"
          @click="handleAllDelete"
        >
          批量删除
        </el-button> -->
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="id"
          label="商品代码"
          width="150"
          align="center"
          fixed="left"
        />
        <el-table-column
          prop="wname"
          label="商品名称"
          min-width="160"
          align="center"
          fixed="left"
        />
        <el-table-column
          prop="anothername"
          label="别名"
          min-width="160"
          align="center"
        />
        <el-table-column label="图片" width="150" align="center">
          <template #default="{ row }">
            <el-image
              style="width: 100px; height: 100px; border-radius: 6px"
              :src="row.yasuoImgsrc || row.imgsrc"
              :fit="'contain'"
              :preview-src-list="[row.imgsrc]"
              :preview-teleported="true"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="kcshuliang"
          label="库存"
          min-width="100"
          align="center"
        />
        <el-table-column prop="guige" label="规格" width="80" align="center" />
        <el-table-column label="单价（元）" width="100" align="center">
          <template #default="{ row }">
            {{ $filters.filterBalance(row.danjia) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="gys"
          label="供应商"
          min-width="160"
          align="center"
        />

        <el-table-column label="更新时间" min-width="180">
          <template #default="{ row }">
            {{ $filters.dateTimeFilter(row.updateTime) }}
          </template>
        </el-table-column>

        <el-table-column label="首页展示" width="100" align="center">
          <template #default="{ row }">
            <el-switch
              v-model="row.homeShow"
              :loading="homeShowLoading"
              :active-value="1"
              :inactive-value="0"
              @change="homeShowChange(row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="上架" width="100" align="center">
          <template #default="{ row }">
            <el-switch
              v-model="row.isAlive"
              :loading="aliveloading"
              :active-value="1"
              :inactive-value="0"
              @change="aliveChange(row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="170">
          <template #default="{row}">
            <!-- <el-button
              v-permission="['infoGoods']"
              size="small"
              @click="toDetail(row.id)"
            >
              详情
            </el-button> -->

            <el-button
              v-permission="['editGoods']"
              type="primary"
              size="small"
              @click="handleUpdate(row)"
            >
              编辑
            </el-button>

            <!-- <el-dropdown style="margin-left: 12px;">
              <el-button size="small">
                <span class="el-dropdown-link">
                  更多 <ArrowDown class="el-icon" style="margin-left: 5px;" />
                </span>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu class="user-dropdown">
                  <el-dropdown-item>
                    <el-button
                      v-permission="['deleteOrder']"
                      type="danger"
                      size="small"
                      style="width: 72px"
                      @click="handleDelete(row)"
                    >
                      删除
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20, 50, 100, 500, 1000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <create-dialog
      v-model="createDialogVisible"
      :info="editSelectClient"
      @updateClient="getListData"
    ></create-dialog>
  </div>
</template>

<script setup>
import { ref, onActivated, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { goodslist, deleteGoods, updateGoods } from '@/api/goods.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import { ArrowDown, Refresh } from '@element-plus/icons-vue'
import search from './components/search.vue'
import CreateDialog from './components/create.vue'

const store = useStore()

// 数据相关
const tableData = ref([])
const multipleTable = ref(null)
const total = ref(0)
const page = ref(1)
const size = ref(10)
const searchFrom = ref({})
const loading = ref(false)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await goodslist({
    current: page.value,
    size: size.value,
    ...Object.fromEntries(
      Object.entries(searchFrom.value).filter(
        item => item[1] !== '' || item[1] === 0
      )
    )
  })
  tableData.value = result.items
  total.value = result.total
  loading.value = false
}

// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 新增点击事件
const createDialogVisible = ref(false)
const handleCreate = () => {
  createDialogVisible.value = true
}
// 修改点击事件
const editSelectClient = ref({})
const handleUpdate = row => {
  editSelectClient.value = row
  createDialogVisible.value = true
}
watch(createDialogVisible, val => {
  if (!val) editSelectClient.value = {}
})

/**
 * 处理删除
 */
const handleDelete = row => {
  ElMessageBox.confirm('确定要删除 ' + row.wname + ' 的信息吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deleteFn([row.id])
  })
}
/**
 * 批量删除
 */
const handleAllDelete = () => {
  ElMessageBox.confirm('是否批量删除此信息吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(async () => {
    const ids = selectedIndexs.value.map(item => item.id)
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index]
      await deleteFn([id], index === ids.length - 1)
    }
  })
}
/**
 * 处理删除请求
 */
const deleteFn = async (ids, refresh = true) => {
  try {
    await deleteGoods(ids)
    if (refresh) {
      ElMessage.success('删除成功')
      // 重新渲染数据
      getListData()
      multipleTable.value.clearSelection()
    }
  } catch (error) {}
}

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

// 搜索
const searchClick = e => {
  page.value = 1
  searchFrom.value = e
  getListData()
}
// 清空
const clearClick = () => {
  page.value = 1
  searchFrom.value = {}
  getListData()
}

const homeShowLoading = ref(false)
const homeShowChange = async row => {
  homeShowLoading.value = true
  try {
    await updateGoods(row)
    ElMessage.success('更新成功')
  } finally {
    homeShowLoading.value = false
  }
}

const aliveloading = ref(false)
const aliveChange = async row => {
  aliveloading.value = true
  try {
    await updateGoods(row)
    ElMessage.success('更新成功')
  } finally {
    aliveloading.value = false
  }
}

const router = useRouter()
/**
 * 进入详情页
 */
const toDetail = id => {
  router.push('/order/info/' + id)
}
</script>

<style lang="scss" scoped>
.user-manage-container {
  .header {
    margin-bottom: 22px;
    text-align: right;
  }
  ::v-deep .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  ::v-deep .el-tag {
    margin-right: 6px;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}

.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
</style>
