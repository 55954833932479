<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="1200px"
    draggable
    destroy-on-close
    :z-index="2010"
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        :rules="goodsFormRules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="wname" required>
              <el-input
                v-model.trim="form.wname"
                @change="wnameChange"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="助记码">
              <el-input v-model="form.zjm" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="销售单价（元）" prop="danjia" required>
              <el-input v-model.trim="form.danjia"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格">
              <el-input v-model.trim="form.guige"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属店铺" prop="gysid" required>
              <el-select-v2
                v-model="form.gysid"
                filterable
                :options="shopOptions"
                :loading="searchSHopLoading"
                placeholder="请选择所属店铺"
                style="width: 300px"
                size="large"
                @change="shopSelectChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="库存数量" prop="kcshuliang" required>
              <el-input-number
                v-model="form.kcshuliang"
                :min="1"
                controls-position="right"
                size="large"
                style="width: 100%"
                placeholder="请填写库存数量"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="分类">
          <GoodsClass
            v-model:dl="form.dl"
            v-model:zl="form.zl"
            v-model:xl="form.xl"
          />
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <el-form-item label="单位设置">
              <el-input
                v-model.trim="form.jcdwb"
                type="number"
                placeholder="请填写数量"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <el-select
                v-model="form.jcdw"
                placeholder="基础单位"
                filterable
                size="large"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dwOptions"
                  :key="item.id"
                  :label="item.unitName"
                  :value="item.unitName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="商户描述">
          <!-- <view
              v-html="form.detail"
              style="width: 100%; height: auto"
            /> -->

          <tinyMCE
            v-model:tinyMCEInit="tinyMCEInit"
            v-model:content="form.detail"
          />
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input
                v-model="form.remarks"
                :rows="3"
                type="textarea"
                placeholder="备注"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="首页展示">
          <el-radio-group v-model="form.homeShow">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上架">
          <el-radio-group v-model="form.isAlive">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div class="photo-container" style="width: 255px;">
        <el-form :model="form" label-width="80px" label-position="top">
          <el-form-item label="图片">
            <UploadImage
              v-model="form.goodsImage"
              width="200px"
              height="200px"
              format="array"
            ></UploadImage>
            <!-- <el-input v-model="form.goodsImage" placeholder="图片"></el-input> -->
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed, onMounted } from 'vue'
import { storelist } from '@/api/store.js'
import { addGoods, updateGoods, listUnit } from '@/api/goods.js'
import { ElMessage } from 'element-plus'
import { pinyin } from 'pinyin-pro'
import UploadImage from '@/components/UploadImage/index.vue'
import GoodsClass from './goodsClass.vue'
import { rules } from '../data.js'

/**********************************/
import tinyMCE from '@/components/TinymceEditor/index.vue'
const tinyMCEInit = ref(false)
const initForm = () => {
  tinyMCEInit.value = true
}
onMounted(() => {
  initForm()
})
/**********************************/

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return JSON.stringify(props.info) === '{}' ? '创建' : '修改'
})

// 数据源
const form = ref({
  jcdwb: 1,
  homeShow: 0,
  isAlive: 1
})
// 取消
const closed = () => {
  form.value = {
    jcdwb: 1,
    homeShow: 0,
    isAlive: 1
  }
  emits('update:modelValue', false)
}

watch(
  () => form.value.goodsImage,
  val => {
    form.value.imgsrc = val ? val[0].img : ''
    form.value.yasuoImgsrc = val ? val[0].ysImg : ''
  }
)

// 提交事件
const submitLoading = ref(false)
const ruleFormRef = ref(null)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (!valid) {
      console.log('error submit!', fields, Object.keys(fields)[0])
      ElMessage.error(fields[Object.keys(fields)[0]][0].message)
      return
    }

    submitLoading.value = true
    if (form.value.detail) {
      form.value.detail = form.value.detail
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&nbsp;/gi, ' ')
    }
    form.value.anothername = form.value.wname

    // console.log('form.value :>> ', form.value)
    try {
      form.value.id ? await updateGoods(form.value) : await addGoods(form.value)
      ElMessage.success('更新成功')
      emits('updateClient')
      closed()
    } finally {
      submitLoading.value = false
    }
  })
}

// 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    form.value = JSON.parse(JSON.stringify(props.info))
    if (props.info.imgsrc) {
      form.value.goodsImage = [
        { img: form.value.imgsrc, ysImg: form.value.yasuoImgsrc }
      ]
    }
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    loading.value = false
  }
}
watch(
  [() => props.info, () => props.modelValue],
  async ([infoVal, modelValueVal]) => {
    if (JSON.stringify(infoVal) !== '{}') getDetail()
    if (modelValueVal && shopOptions.value.length === 0) {
      searchShopMethod()
    }
    if (modelValueVal && dwOptions.value.length === 0) {
      getDwOptions()
    }
  }
)

const wnameChange = e => {
  const py = pinyin(e, { pattern: 'first', toneType: 'none' })
  form.value.zjm = py.replace(/\s/g, '')
}

const searchSHopLoading = ref(false)
const shopOptions = ref([])
const searchShopMethod = async () => {
  searchSHopLoading.value = true
  try {
    const res = await storelist({
      current: 1,
      size: 100000
    })
    searchSHopLoading.value = false
    res.items.forEach(item => {
      item.value = item.id
      item.label = item.gname
    })
    shopOptions.value = res.items
  } catch (error) {
    console.log('error :>> ', error)
  }
}
const shopSelectChange = e => {
  const shop = shopOptions.value.find(item => item.id === e)
  form.value.gys = shop.gname || ''
}

const dwOptions = ref([])
const getDwOptions = async () => {
  try {
    const res = await listUnit()
    dwOptions.value = res
  } catch (error) {
    console.log('error :>> ', error)
  }
}
const goodsFormRules = rules
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
</style>
