/**
 * 动态加载js文件
 * @param {*} srcUrl 文件地址
 * @returns Promise
 */
export function loadJs(srcUrl) {
  return new Promise((resolve, reject) => {
    // 判断当前js是否已经加载过
    const scriptNodes = [].slice.call(document.querySelectorAll('script')).map(item => item.src)
    if (scriptNodes.includes(srcUrl)) return resolve()

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = srcUrl
    document.body.appendChild(script)
    script.onload = () => {
      resolve()
    }
    script.onerror = (err) => {
      reject(err)
    }
  })
}
