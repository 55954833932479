export const rules = {
  /* name: [
    { required: true, message: 'Please input Activity name', trigger: 'blur' },
    { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
  ], */
  wname: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
  danjia: [
    { required: true, message: '请输入销售单价（元）', trigger: 'blur' }
  ],
  gysid: [{ required: true, message: '请选择所属店铺', trigger: 'blur' }],
  kcshuliang: [{ required: true, message: '请输入库存数量', trigger: 'blur' }]
}
