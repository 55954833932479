<template>
  <div class="tinyMce-container">
    <textarea :id="editorId"></textarea>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { loadJs } from './js/dynamicLoadScript'
// import { getUuidv4 } from './js/uuid'
// console.log('getUuidv4 :>> ', getUuidv4)

// tinyMce初始化
const props = defineProps({
  tinyMCEInit: {
    type: Boolean,
    require: true
  },
  content: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:tinyMCEInit', 'update:content'])
const store = useStore()

const contentData = ref('')

// 富文本实例
const editorInstance = ref(null)
// 获取富文本编辑器唯一的ID
const editorId = ref()

const initTinyMCEId = () => {
  return new Promise(resolve => {
    if (editorInstance.value && !editorInstance.value.destroyed) {
      return resolve()
    }
    // eslint-disable-next-line
    tinymce.init({
      // 编辑器ID
      selector: `#${editorId.value}`,
      license_key: 'gpl',
      // 编辑器语言
      language: 'zh_CN',
      promotion: false,
      branding: false,
      resize: 'both',
      placeholder: '输入...',
      cache_suffix: '?v=7.1.2',
      // 皮肤
      skin: 'oxide',
      // 内容css样式
      content_css: [],
      content_css_cors: true,
      content_style: 'p { margin: 0 }',
      paste_data_images: true,
      paste_webkit_styles: 'all',
      // 插件
      plugins: [
        'advlist',
        'autolink',
        'link',
        'image',
        'lists',
        'charmap',
        'preview',
        'anchor',
        'pagebreak',
        'searchreplace',
        'wordcount',
        'visualblocks',
        'visualchars',
        'code',
        'fullscreen',
        'insertdatetime',
        'media',
        'table',
        'emoticons',
        'help'
      ],
      font_size_formats:
        '8px 10px 12px 13px 14px 15px 16px 17px 18px 19px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px 37px 38px 40px',
      // 工具栏配置
      toolbar: [
        'code undo redo styles fontsize removeformat bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent link  forecolor backcolor fullscreen preview code',
        'wordcount insertdatetime strikethrough emoticons image media'
      ],
      // images_upload_handler: example_image_upload_handler,
      file_picker_callback: filePickerCallback,
      menu: {
        file: {
          title: 'File',
          items:
            'newdocument restoredraft | preview | importword exportpdf exportword | print | deleteallconversations'
        },
        edit: {
          title: 'Edit',
          items:
            'undo redo | cut copy paste pastetext | selectall | searchreplace'
        },
        view: {
          title: 'View',
          items:
            'code revisionhistory | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments'
        },
        insert: {
          title: 'Insert',
          items:
            'image link media addcomment pageembed codesample inserttable | math | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime'
        },
        format: {
          title: 'Format',
          items:
            'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat'
        },
        tools: {
          title: 'Tools',
          items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount'
        },
        table: {
          title: 'Table',
          items:
            'inserttable | cell row column | advtablesort | tableprops deletetable'
        },
        help: { title: 'Help', items: 'help' }
      },
      setup: editor => {
        editor.on('Change', () => {
          contentData.value = editor.getContent()
          emit('update:content', editor.getContent())
        })
      },
      init_instance_callback: editor => {
        editorInstance.value = editor

        resolve()
      }
    })
  })
}

// 图片上传处理
// eslint-disable-next-line
/* const example_image_upload_handler = (blobInfo, progress) => {
  return new Promise((resolve, reject) => {
    console.log('图片上传处理\nblobInfo:\n', blobInfo, '\nprogress:', progress)
  })
} */

/**
 * 上传图片
 * @param {*} formDataObj
 * @param {*} config
 */
const uploadImageReq = async (formDataObj, config) => {
  const formData = new FormData()
  Object.keys(formDataObj).forEach(key => {
    formData.append(key, formDataObj[key])
  })
  try {
    // 为了方便,我是在本地解决跨域问题
    const res = await axios.post(
      process.env.VUE_APP_BASE_API + '/manager/warehouse/uploadImage',
      formData,
      {
        onUploadProgress: e => {
          config?.progress && config.progress((e.loaded / e.total) * 100)
        },
        headers: {
          // smms图床的token
          // smms图床申请地址,放在了上面
          Authorization: `Bearer ${store.getters.token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return res
  } catch (error) {
    ElMessage.error(error)
    return {}
  }
}
/**
 * 上传视频
 * @param {*} formDataObj
 * @param {*} config
 */
const uploadMediaReq = async (formDataObj, config) => {
  const formData = new FormData()
  Object.keys(formDataObj).forEach(key => {
    formData.append(key, formDataObj[key])
  })
  try {
    // 为了方便,我是在本地解决跨域问题
    const res = await axios.post(
      process.env.VUE_APP_BASE_API + '/manager/warehouse/uploadVideo',
      formData,
      {
        onUploadProgress: e => {
          config?.progress && config.progress((e.loaded / e.total) * 100)
        },
        headers: {
          // smms图床的token
          // smms图床申请地址,放在了上面
          Authorization: `Bearer ${store.getters.token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return res
  } catch (error) {
    ElMessage.error(error)
    return {}
  }
}

const uploadFile = ({ accept, fileType, errorHandle, uploadHandle }) => {
  const input = document.createElement('input')
  // 是不是可支持的上传格式
  const fileIsAdjective = file => {
    const suffix = `.${file.type.toLowerCase().split('/')[1]}`
    return accept.includes(suffix)
  }

  input.setAttribute('type', 'file')
  input.setAttribute('accept', accept)

  input.onchange = e => {
    const file = e.target.files[0]
    if (!fileIsAdjective(file)) {
      errorHandle(`请上传 ${accept}后缀的文件`)
    } else {
      uploadHandle({ file })
    }
  }
  input.click()
  input.remove()
}

const getSupportedFileType = fileTypes => {
  if (!fileTypes || fileTypes.length === 0) return ''
  return fileTypes.map(item => item.toLowerCase()).toString()
}
/**
 * 我只写了一个图片上传,视频或者音频或者其他文件类似
 */
const filePickerCallback = (callback, value, meta) => {
  const supportedImageTypes = [
    '.jpeg',
    '.jpg',
    '.png',
    '.gif',
    '.bmp',
    '.svg',
    '.webp'
  ]
  const supportedMediaTypes = [
    '.mp4',
    '.webm',
    '.ogg',
    '.flv',
    '.avi',
    '.wmv',
    '.mov',
    '.mkv'
  ]
  /*   const supportedFileTypes = [
    '.pdf',
    '.doc',
    '.docx',
    '.txt',
    '.rtf',
    '.xls',
    '.xlsx',
    '.csv',
    '.ppt',
    '.pptx',
    '.zip',
    '.rar',
    '.7z',
    '.tar',
    '.gz',
    '.bz2',
    '.iso',
    '.dmg',
    '.apk'
  ] */

  if (meta.filetype === 'media') {
    uploadFile({
      accept: getSupportedFileType(supportedMediaTypes),
      fileType: 'media',
      // 空处理函数
      errorHandle: desc => {
        // eslint-disable-next-line
        callback('', { alt: desc })
      },
      // 上传处理函数
      uploadHandle: ({ file }) => {
        uploadMediaReq({
          file: file
        }).then(
          res => {
            // eslint-disable-next-line
            callback(res?.data?.data || '', { alt: file.name })
          },
          error => {
            // eslint-disable-next-line
            callback('', { alt: error.toString() })
          }
        )
      }
    })
  }
  if (meta.filetype === 'image') {
    uploadFile({
      accept: getSupportedFileType(supportedImageTypes),
      fileType: 'image',
      // 空处理函数
      errorHandle: desc => {
        // eslint-disable-next-line
        callback('', { alt: desc })
      },
      // 上传处理函数
      uploadHandle: ({ file }) => {
        uploadImageReq({
          file: file
        }).then(
          res => {
            // eslint-disable-next-line
            callback(res?.data?.data?.img || '', { alt: file.name })
          },
          error => {
            // eslint-disable-next-line
            callback('', { alt: error.toString() })
          }
        )
      }
    })
  }
}

// eslint-disable-next-line
const init_load = async () => {
  await loadJs(
    'https://lsol-house-upload.oss-cn-hangzhou.aliyuncs.com/2020-09-29wxls/tinymceAll/7.1.2/tinymce.min.js'
  )
  await initTinyMCEId()
}

// 监听多个值，一个tinyMCE实例化 一个内容
watch(
  [() => props.tinyMCEInit, () => props.content],
  async ([tinyMCEInitVal, contentVal]) => {
    console.log(tinyMCEInitVal, contentVal)
    if (tinyMCEInitVal) {
      await nextTick()
      editorId.value = `editor-${new Date().getTime()}`
      await init_load()
    } else {
      if (!editorInstance.value) return
      editorInstance.value.setContent('')
      editorInstance.value.destroy()
      editorInstance.value = null
    }
    if (!editorInstance.value) return
    if (contentVal === editorInstance.value.getContent()) return
    editorInstance.value.setContent(contentVal)
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.tinyMce-container {
  width: 100%;
}
::v-deep .tox-tinymce-aux {
  z-index: 2011;
}
::v-deep .tox-dialog-wrap {
  z-index: 2011;
}
</style>
